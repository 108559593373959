/* global google, stores */
/* eslint no-underscore-dangle: off */
require('smoothscroll-polyfill').polyfill();

const colorWater = '#cddbe3';
const colorText = '#5B867E';
const colorPark = '#E5E5E5';
const colorLand = '#f2f5f6';
const colorRoads = '#ffffff';

window._gmaps = window._gmaps || {
  map: null,
  markers: [],
  currentMarker: null,
  infowindow: null
};
const gmaps = window._gmaps;

let infoWindow = null;
const googleMapsStyle = [
  { elementType: 'geometry', stylers: [{ color: colorLand }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#FFFFFF' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: colorText }] }, {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: colorText }]
  }, {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#FF0000' }]
  }, {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: colorRoads }]
  }, {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: colorRoads }]
  }, {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: colorRoads }]
  }, {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: colorText }]
  }, {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: colorWater }]
  }, {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: colorText }]
  }, {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#FFFFFF' }]
  }, {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [{ color: colorPark }]
  }, {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [{
      color: colorRoads
    }]
  }, {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{
      color: colorText
    }]
  }
];
const defaultMapValues = {
  zoom: 11,
  center: { lat: 25.0971648, lng: 55.1541504 }
};
const markerIconDefault = '/ResourcePackages/Theme/assets/dist/images/icons/marker-default.svg';
const markerIconYellow = '/ResourcePackages/Theme/assets/dist/images/icons/marker-yellow.svg';
const markerIconBlue = '/ResourcePackages/Theme/assets/dist/images/icons/marker-blue.svg';
let markerConfig;

const showMarker = (marker) => {
  const assignedToMap = !!marker.getMap();

  if (!assignedToMap) {
    marker.setMap(gmaps.map);
  }
};
const hideMarker = (marker) => {
  const assignedToMap = !!marker.getMap();

  if (assignedToMap) {
    marker.setMap(null);
  }
};

const setCurrentMarkerIcon = (markerIcon) => {
  if (markerConfig && gmaps.currentMarker !== null) {
    gmaps.currentMarker.setIcon({
      url: markerIcon,
      scaledSize: markerConfig.scaledSize,
      origin: markerConfig.origin,
      anchor: markerConfig.anchor
    });
  }
};

const removeDefaultInfoWindow = () => {
  const removeWindow = () => {
    const gmStyleIw = document.querySelector('.gm-style-iw');
    if (gmStyleIw && gmStyleIw.previousElementSibling) {
      gmStyleIw.parentNode.removeChild(gmStyleIw.previousElementSibling);
      return true;
    }

    return false;
  };

  google.maps.event.addListenerOnce(gmaps.map, 'idle', () => {
    if (!removeWindow()) {
      let loopCounter = 0;
      const interval = setInterval(() => {
        if (removeWindow()) {
          clearInterval(interval);
        }

        loopCounter += 1;
        if (loopCounter >= 500) {
          clearInterval(interval);
        }
      }, 5);
    }
  });
};

const fitMarkersIntoScreen = (visibleMarkers) => {
  if (visibleMarkers.length === 0) {
    gmaps.map.setCenter(defaultMapValues.center);
    gmaps.map.setZoom(defaultMapValues.zoom);
    return;
  }

  const bounds = new google.maps.LatLngBounds();

  for (let visibleMarkerIdx = 0; visibleMarkerIdx < visibleMarkers.length; visibleMarkerIdx += 1) {
    bounds.extend(visibleMarkers[visibleMarkerIdx].getPosition());
  }

  gmaps.map.fitBounds(bounds);

  // remove one zoom level to ensure no marker is on the edge
  gmaps.map.setZoom(gmaps.map.getZoom() - 1);

  // set a minimum zoom
  if (gmaps.map.getZoom() > 15) {
    gmaps.map.setZoom(15);
  }
};

// hide the marker info if click outside the info box
if (document.getElementsByClassName("locations-section") && document.getElementsByClassName("locations-section")[0]) {
  document.getElementsByClassName("locations-section")[0].onclick = function(e) {
    if (e.target.parentNode.parentNode.className === "gm-style") {
      hideMarkerInfo();
    }
  };
}


const hideMarkerInfo = () => {
  //setCurrentMarkerIcon(markerIconDefault);
  if (gmaps.infowindow) {
    gmaps.infowindow.close();
  }
  gmaps.currentMarker = null;
};

const showMarkerInfo = (marker) => {
  //setCurrentMarkerIcon(markerIconDefault);
  if (gmaps.infowindow) {
    gmaps.infowindow.close();
  }

  const directionUrl = `https://www.google.com/maps?saddr=My+Location&daddr=${marker.data.coordinates.lat.toString()},${marker.data.coordinates.lng.toString()}`;
  let template = document.getElementById('map-template-infowindow').innerHTML;

  template = template
    .replace(/\[\[name\]\]/g, marker.data.name)
    .replace(/\[\[address\]\]/g, marker.data.address)
    .replace(/\[\[phone\]\]/g, marker.data.phoneNumber)
    .replace(/\[\[hours\]\]/g, marker.data.openingTime)
    .replace(/\[\[email\]\]/g, marker.data.email)
    .replace(/\[\[directionurl\]\]/g, directionUrl);
  if (marker.data.email === null || marker.data.email === "") {
      template = template
      .replace(/\[\[emailclass\]\]/g, 'hidden');
  }
  else {
      template = template
      .replace(/\[\[emailclass\]\]/g, '');
  }

  if (marker.data.phoneNumber === null || marker.data.phoneNumber === "") {
      template = template
      .replace(/\[\[phoneclass\]\]/g, 'hidden');
  }
  else {
      template = template
      .replace(/\[\[phoneclass\]\]/g, '');
  }

  if (marker.data.openingTime === null || marker.data.openingTime === "") {
      template = template
      .replace(/\[\[hoursclass\]\]/g, 'hidden');
  }
  else {
      template = template
      .replace(/\[\[hoursclass\]\]/g, '');
  }

  gmaps.infowindow = new google.maps.InfoWindow({
    content: template.toString(),
    maxWidth: 369
  });

  removeDefaultInfoWindow();

  gmaps.infowindow.open(gmaps.map, marker);


  gmaps.currentMarker = marker;

  gmaps.map.setCenter(marker.getPosition());
};

const generateMarkers = () => {

    // Yellow markers
    stores.forEach((store) => {
        if (store.serviceType > store.serviceTypeFleet) {
            var markerIcon = markerIconYellow;

            const marker = new google.maps.Marker({
                position: store.coordinates,
                animation: google.maps.Animation.DROP,
                map: gmaps.map,
                icon: {
                    url: markerIcon,
                    scaledSize: markerConfig.scaledSize,
                    origin: markerConfig.origin,
                    anchor: markerConfig.anchor
                },
                data: store,
                serviceType: 'individual'
            });

            marker.addListener('click', () => showMarkerInfo(marker));

            gmaps.markers.push(marker);
        }
    });

    // Red markers
    stores.forEach((store) => {

        if (store.serviceTypeFleet.length > 0) {
            var markerIcon = markerIconDefault;

            const marker = new google.maps.Marker({
                position: store.coordinates,
                animation: google.maps.Animation.DROP,
                map: gmaps.map,
                icon: {
                    url: markerIcon,
                    scaledSize: markerConfig.scaledSize,
                    origin: markerConfig.origin,
                    anchor: markerConfig.anchor
                },
                data: store,
                serviceType: 'fleet'
            });

            marker.addListener('click', () => showMarkerInfo(marker));

            gmaps.markers.push(marker);
        }
    });

};

const updateMarkerConfig = () => {

    markerConfig = {
      scaledSize: new google.maps.Size(18, 26),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(9, 26)
    };
};

const resizeResponsiveMarkers = () => {
  setTimeout(() => {
    updateMarkerConfig();

    gmaps.markers.forEach((marker) => {
      marker.setIcon({
        url: marker.icon.url,
        scaledSize: markerConfig.scaledSize,
        origin: markerConfig.origin,
        anchor: markerConfig.anchor
      });
    });
  }, 500);
};


const initMap = () => {
    var initMapInner = function() {
        if (document.getElementById('map') === null) {
            return;
        }
        infoWindow = new google.maps.InfoWindow;
        gmaps.map = new google.maps.Map(document.getElementById('map'), {
            scrollwheel: gesture === 'cooperative' ? null : false,
            zoom: defaultMapValues.zoom,
            center: defaultMapValues.center,
            mapTypeControl: false,
            zoomControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            styles: googleMapsStyle,
            gestureHandling: gesture
        });

        updateMarkerConfig();

        // Event handlers
        document.querySelector('[data-zoom-in]').addEventListener('click', () => {
            const currentZoom = gmaps.map.getZoom();
            gmaps.map.setZoom(currentZoom + 1);
        });

        document.querySelector('[data-zoom-out]').addEventListener('click', () => {
            const currentZoom = gmaps.map.getZoom();
            gmaps.map.setZoom(currentZoom - 1);
        });

        window.addEventListener('orientationchange', resizeResponsiveMarkers);

        generateMarkers();
        fitMarkersIntoScreen(gmaps.markers);
        setCurrentLocation();
    }
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', initMapInner); // Document still loading so DomContentLoaded can still fire :)
    } else {
        initMapInner();
    }
};

const filterMarkers = (isSelected) => {
  const visibleMarkers = [];

  hideMarkerInfo();

  gmaps.markers.forEach((marker) => {
    const isMarkerSelectedVal = isSelected(marker);
    if (isMarkerSelectedVal) {
      showMarker(marker);
      visibleMarkers.push(marker);
    } else {
      hideMarker(marker);
    }
  });

  fitMarkersIntoScreen(visibleMarkers);

  return visibleMarkers.map(marker => marker.data);
};

const selectMarker = (dataId) => {
  google.maps.event.trigger(gmaps.markers.find(marker => marker.data.id === dataId), 'click');
};
const handleLocationError =(browserHasGeolocation, infoWindow, pos) => {
}
const setCurrentLocation = () => {
    
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
            var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };

            gmaps.map.setCenter(pos);

            const marker = new google.maps.Marker({
                position: pos,
                animation: google.maps.Animation.DROP,
                map: gmaps.map,
                icon: {
                    url: markerIconBlue,
                    scaledSize: markerConfig.scaledSize,
                    origin: markerConfig.origin,
                    anchor: markerConfig.anchor
                }
            });
            function rad(x) {return x*Math.PI/180;}
            function find_closest_marker( lat,lng ) {
                var R = 6371; // radius of earth in km
                var distances = [];
                var closest = -1;
                for(var i=0;i<gmaps.markers.length; i++ ) {
                    var mlat = gmaps.markers[i].position.lat();
                    var mlng = gmaps.markers[i].position.lng();
                    var dLat  = rad(mlat - lat);
                    var dLong = rad(mlng - lng);
                    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                        Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
                    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
                    var d = R * c;
                    distances[i] = d;
                    if ( closest === -1 || d < distances[closest] ) {
                        closest = i;
                    }
                }

                return gmaps.markers[closest];
            }

            var closest = find_closest_marker(pos.lat,pos.lng)

            

            var currentPlace = new google.maps.LatLng(pos.lat,pos.lng);
            var closestPlace = closest.getPosition();
            var bounds = new google.maps.LatLngBounds();
            bounds.extend(currentPlace);
            bounds.extend(closestPlace);
            
            gmaps.map.fitBounds(bounds);

            gmaps.markers.push(marker);


        }, function() {
            handleLocationError(true, infoWindow, gmaps.map.getCenter());
        });
    } else {
        // Browser doesn't support Geolocation
        handleLocationError(false, infoWindow, gmaps.map.getCenter());
    }
}
export default {
  initMap,
  filterMarkers,
  selectMarker
};
